<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Møterom</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger møterom.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/administrer/moterom" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Aktivitetstyper</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger ulike aktivitetstyper.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/administrer/arrangementetstyper" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="user.id == '368a1b32-43cb-47c0-8013-8512f487239a'">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-account</v-icon>
                        <span>2-faktor innlogging</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Oppdater alle registerte brukere med 2-faktor innlogging</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-on:click="updateKeycloack" outlined>Kjør</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="user.id == '368a1b32-43cb-47c0-8013-8512f487239a'">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-account</v-icon>
                        <span>2-faktor innlogging</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Bruker uten mobil</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-on:click="usersWithout2Factor" outlined>Kjør</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="user.id == '368a1b32-43cb-47c0-8013-8512f487239a'">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-tag</v-icon>
                        <span>Løpenummer</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Generer løpeummer</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-on:click="updateLopenummer" outlined>Kjør </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Settings',

    computed: {
        ...mapGetters('api', ['user']),
    },
    methods: {
        ...mapActions('api', ['request']),
        ...mapActions(['snackbar']),

        updateKeycloack: async function () {
            const response = await this.request({
                method: 'get',
                url: '/person/addParametersToExistingUsers',
            });

            console.log(response);

            this.snackbar('Oppdatert');
        },

        usersWithout2Factor: async function () {
            const response = await this.request({
                method: 'get',
                url: '/person/aktiver2Faktor',
            });

            console.log(response);
            this.snackbar('Hentet');
        },

        updateLopenummer: async function () {
            const response = await this.request({
                method: 'get',
                url: '/api/updateLopenummer',
            });
            console.log(response);
            this.snackbar('Generert');
        },
    },
};
</script>
